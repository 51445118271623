html,
body,
#root {
  height: 100%;
  width: 100%;
  font-weight: 400;
  color: #000;
}

body {
  
  padding: 0 !important;
  overflow: auto !important;
}
* {
  box-sizing: border-box;
}

:root {
  background-color: #ffffff;
}

:root .dark {
  background-color: #181818;
}

html {
  background: #ffffff;
  box-sizing: border-box;
  font-size: 62.5%;
}

[data-f-id="pdf"] {
  display: none !important;
}

p[data-f-id="pdf"] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  align-items: center;
  line-height: 20px;
}

html.dark {
  background: #181818;
}

#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: black !important;
  background: #ffffff;
  height: 100%;
}

.dark body {
  color: white;
  background: #181818;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  z-index: 1031;
  background: #4592ea;
}

/* Designing for scroll-bar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #afafaf;
  border-radius: 60px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d5d5d5;
}

#nprogress .bar {
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

.modal {
  max-width: 100% !important;
}
.modal .modal-box {
  max-width: fit-content;
  background-color: white;
}

select.select {
  flex-shrink: 1;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}
.pos-shadow {
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.16);
}

input::placeholder {
  line-height: "28px";
  height: 100%;
}

.select:disabled,
.input-disabled,
input:disabled {
  background-color: transparent !important;
  pointer-events: none;
  --tw-text-opacity: 1 !important;
  border-color: rgba(236, 238, 241, var(--tw-border-opacity)) !important;
}

.radio:disabled {
  opacity: 1 !important;
}

.radio[type="radio"]:checked {
  border-color: #8995a6;
  background: #8995a6;
}
.custom.radio[type="radio"]:checked {
  border-color: #1b74e8;
  background: #1b74e8;
}
.radio.checked:hover,
.radio-primary.checked:checked {
  background-color: #8995a6;
  background: #8995a6;
}
.react-datepicker-popper {
  min-width: 330px;
}
.react-datepicker-popper .react-datepicker__triangle {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.truncate-1 > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.truncate-2 > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-3 > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-4 > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}


.Toastify__toast-container {
  left: unset !important;
  width: 260px !important;
  z-index: 10000 !important;
}
.Toastify__toast-container svg {
  height: 100% !important;
}
.Toastify__toast {
  min-height: 44px !important;
  margin: 0;
}

.Toastify__toast--success {
  background-color: #eef8eb !important;
  color: #000 !important;
}
.Toastify__toast.Toastify__toast-body {
  color: #000 !important;
  font-size: 13px;
}
.Toastify__toast .Toastify__toast--error {
  height: 44px !important;
  padding: 0px 8px !important;
  display: flex;
  align-items: center;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
}

#header {
  position: sticky;
  top: 0;
  z-index: 999;
}
@media (max-width: 768px) {
  #header {
    position: sticky;
    z-index: 999;
    width: 100%;
  }
}