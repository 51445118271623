img.flashing:hover {
	opacity: 1;
	-webkit-animation: flash 1.5s;
	animation: flash 1.5s;
}
@-webkit-keyframes flash {
	0% {
		opacity: .6;
	}
	100% {
		opacity: 1;
	}
}
@keyframes flash {
	0% {
		opacity: .6;
	}
	100% {
		opacity: 1;
	}
}