.bingo-single-datepicker {
  margin: 0;
  padding: 8px 16px;
}

.bingo-single-datepicker .rdp-months .rdp-caption {
  position: relative;
  padding: 1.2rem;
}
.bingo-single-datepicker .rdp-months .rdp-table .rdp-tfoot {
  border-top: 1px solid #eceef1;
}

.bingo-single-datepicker .rdp-months .rdp-caption .rdp-caption_label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: "#000";
}

.bingo-single-datepicker .rdp-months .rdp-caption .rdp-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bingo-single-datepicker .rdp-months .rdp-nav_button {
  border: 1px solid #d6d9df;
  border-radius: 6px;
  background-color: white;
  width: 32px;
  height: 32px;
  padding: 8px;
}

.bingo-single-datepicker .rdp-months .rdp-tfoot {
  position: relative;
}

.bingo-single-datepicker .rdp-button_reset.rdp-button:active,
.bingo-single-datepicker .rdp-button_reset.rdp-button:hover {
  border: none;
}

.bingo-single-datepicker .rdp-button_reset.rdp-button.rdp-day {
  width: 32px;
  height: 32px;
  font-size: 14px;
  margin: 4px;
}

.bingo-single-datepicker .rdp-button_reset.rdp-button.rdp-day.rdp-day_selected {
  background-color: #0f3e89;
  border: none;
  border-radius: 8px;
}

.bingo-single-datepicker .rdp-button_reset.rdp-button.rdp-day.rdp-day_outside {
  background-color: transparent;
  border: none;
  opacity: 0.5;
  pointer-events: none;
}

.bingo-single-datepicker .rdp-tfoot tr td {
  padding: 0;
  margin: 0;
}

.bingo-single-datepicker .rdp-tfoot .dp-footer button {
  color: #1b74e8;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 282px;
}

.bingo-single-datepicker .rdp-tfoot .dp-footer button:disabled {
  background-color: #e1e7ec;
  color: #fff;
  cursor: default;
}
